// Generated by CoffeeScript 1.9.1
var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

exports.SpeechSynth = (function(superClass) {
  extend(SpeechSynth, superClass);

  function SpeechSynth(options) {
    var option;
    if (options == null) {
      options = {};
    }
    this._utterance = new SpeechSynthesisUtterance();
    this._voices = window.speechSynthesis.getVoices();
    if (options.voice) {
      options.voice = this._voices.filter(function(voice) {
        return voice.name === ("" + options.voice);
      })[0];
    }
    for (option in options) {
      this._utterance["" + option] = options["" + option];
    }
  }

  SpeechSynth.prototype.speak = function() {
    return window.speechSynthesis.speak(this._utterance);
  };

  SpeechSynth.prototype.cancel = function() {
    return window.speechSynthesis.cancel(this._utterance);
  };

  SpeechSynth.prototype.pause = function() {
    return window.speechSynthesis.pause(this._utterance);
  };

  SpeechSynth.prototype.resume = function() {
    return window.speechSynthesis.resume(this._utterance);
  };

  SpeechSynth.define("voices", {
    get: function() {
      var i, len, ref, voice, voices;
      voices = [];
      ref = this._voices;
      for (i = 0, len = ref.length; i < len; i++) {
        voice = ref[i];
        voices[_.indexOf(this._voices, voice)] = voice.name;
      }
      return voices;
    }
  });

  SpeechSynth.define("isPending", {
    get: function() {
      return window.speechSynthesis.pending;
    }
  });

  SpeechSynth.define("isSpeaking", {
    get: function() {
      return window.speechSynthesis.speaking;
    }
  });

  SpeechSynth.define("isPaused", {
    get: function() {
      return window.speechSynthesis.paused;
    }
  });

  SpeechSynth.define("text", {
    get: function() {
      return this._utterance.text;
    },
    set: function(value) {
      return this._utterance.text = value;
    }
  });

  SpeechSynth.define("lang", {
    get: function() {
      return this._utterance.lang;
    },
    set: function(value) {
      return this._utterance.lang = value;
    }
  });

  SpeechSynth.define("volume", {
    get: function() {
      return this._utterance.volume;
    },
    set: function(value) {
      return this._utterance.volume = value;
    }
  });

  SpeechSynth.define("rate", {
    get: function() {
      return this._utterance.rate;
    },
    set: function(value) {
      return this._utterance.rate = value;
    }
  });

  SpeechSynth.define("pitch", {
    get: function() {
      return this._utterance.pitch;
    },
    set: function(value) {
      return this._utterance.pitch = value;
    }
  });

  return SpeechSynth;

})(Framer.BaseClass);
